import React, { Fragment, useState, useEffect } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Paper,
  TableContainer,
  Container,
} from "@material-ui/core";
import axios from "axios";
import { apiURL, teacherApiURL } from "../utils";
const Top5Articles = () => {
  const [articleData, setArticleData] = useState([]);
  useEffect(() => {
    fetchDetails();
  }, []);
  const fetchDetails = () => {
    const headers = {
      "Content-type": "application/json",
    };

    axios
      .post(teacherApiURL("article/get_top_articles_by_views"), { headers })
      .then((response) => {
        const resultData = response.data;
        if (resultData.status === true || resultData.status === 1) {
          setArticleData(resultData.result);
        } else {
          console.log("error");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <Fragment>
      <Container maxWidth="lg" className="topTraining">
        <Grid container spacing={3} align="center">
          <Grid item xs={12}>
            <h2>Top 5 Articles By Total Views</h2>
            <TableContainer mt={1} component={Paper} className="customTable">
              <Table size="small" stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell> Title</TableCell>
                    {/*<TableCell>Description</TableCell>*/}
                    <TableCell>Category</TableCell>
                    <TableCell>Views</TableCell>
                    <TableCell>Bookmarks</TableCell>
                    <TableCell>Average Rating</TableCell>
                    {/*<TableCell>Image</TableCell>*/}
                    <TableCell>Share</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {articleData.map((arrList) => (
                    <TableRow key={arrList.fldi_id}>
                      <TableCell>{arrList.fldv_title}</TableCell>
                      <TableCell>{arrList.fldv_category}</TableCell>
                      <TableCell>{arrList.no_of_views}</TableCell>
                      <TableCell>{arrList.no_of_bookmark}</TableCell>
                      <TableCell>{arrList.average_rating}</TableCell>
                      <TableCell>{arrList.no_of_share}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Container>
    </Fragment>
  );
};

export default Top5Articles;
